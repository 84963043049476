import React from "react";
import styled from "styled-components";
import { Group, Text, Title } from "@mantine/core";


const ProgramMap = ({data,xs,type}) => {
  const unit = type === "bootcamp" ? "section" : "week"
  const hasScroll = data && data[`${unit}s_output`].length > 6;
  const isGrow = data && data[`${unit}s_output`].length > 3;

  return (
    <Section type={type}>
      <ProgramMapContainer>
        <Title order={3} mb={20} size={xs ? 18 :24} weight={500} color="#364152">
          Program Map
        </Title>
        <ScrollWrapper hasScroll={hasScroll}>
          <Group 
            grow={isGrow}
            stretch="true"
            noWrap={!xs}
            spacing={xs ? 15 : 0}
            align={xs ? 'center' : 'stretch'}
            justify={xs ? 'center' : 'stretch'}
            style={{ 
              width: hasScroll ? '150%' : '100%',
              display: 'flex',
              flexDirection: xs ? 'column' : 'row',
              alignItems: xs ? 'center' : 'stretch',
              justifyContent: xs ? 'center' : 'stretch',
              margin: xs ? '0' : 'auto',
            }}
          >
            {data && data[`${unit}s_output`].map((u, index) => (
              <Stage xs={xs} key={index} style={{position: 'relative'}} >
                  <Text size={15} weight={300} color="#029199" style={{marginRight: '8px'}}>
                    {unit === "section" ? `Section ${index+1}` : `Week ${index+1}`}
                  </Text>
                  <Text size={16} weight={500} color="#029199">
                    {u[`${unit}_title`]}
                  </Text>
              </Stage>
            ))}
          </Group>
        </ScrollWrapper>
      </ProgramMapContainer>
    </Section>
  );
};

export default ProgramMap;

const Section = styled.section`
  background: ${props => props.type === "steam" ? "#e9f0f1" : "#D9F0A1"};
  width: 100%;
  margin-top: 30px;
`;

const ProgramMapContainer = styled.div`
  max-width: 92%;
  margin: 0 auto;
  padding: 25px 0 45px 0;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Stage = styled.div`
  position: relative;
  width: ${props => props.xs ? '100%' : '19.5%'};
  margin-bottom: ${props => props.xs ? '15px' : '0'};
  max-width: ${props => props.xs ? '300px' : 'none'};
  align-self: ${props => props.xs ? 'center' : 'stretch'};
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 15px;
    margin-left: 0;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #C1C9D2;
    clip-path: polygon(88% 0, 100% 50%, 88% 100%, 0% 100%, 11% 50%, 0% 0%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 1.2px;
    left: 1.2px;
    right: 1.2px;
    bottom: 1.2px;
    background: white;
    clip-path: polygon(88% 0, 100% 50%, 88% 100%, 0% 100%, 11% 50%, 0% 0%);
  }

  > * {
    position: relative;
    z-index: 1;
    padding: 10px 20px;
    padding-left: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ScrollWrapper = styled.div`
  width: 100%;
  overflow-x: ${props => props.hasScroll ? 'auto' : 'hidden'};
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE və Edge üçün */
  scrollbar-width: none;     /* Firefox üçün */
  
  &::-webkit-scrollbar {
    display: none;           /* Chrome, Safari və Opera üçün */
  }
`;
